import AuthSaga from 'x-common/bundles/AuthBundle/models/sagas';
import GamesAreaSaga from 'x-common/bundles/GamesAreaBundle/models/sagas';
import DashboardSaga from 'x-common/bundles/DashboardBundle/models/sagas';
import CompanySaga from 'x-common/bundles/CompanyBundle/models/sagas';
import BillingEntitySaga from 'x-common/bundles/CompanyBundle/models/sagas/BillingEntitySaga';
import UserSaga from 'x-common/bundles/UserBundle/models/sagas/UserSaga';
import UserGroupSaga from 'x-common/bundles/UserBundle/models/sagas/UserGroupSaga';
import ReportSaga from 'x-common/bundles/GeneralReportBundle/models/sagas';
import SessionsReportSaga from 'x-common/bundles/SessionsReportBundle/models/sagas';
import PlayersReportSaga from 'x-common/bundles/PlayersReportBundle/models/sagas';
import PromotionsReportSaga from 'x-common/bundles/PromotionsReportBundle/models/sagas';
import DailyDropSaga from 'x-common/bundles/DailyDropBundle/models/sagas';
import TournamentSaga from 'x-common/bundles/TournamentRaceBundle/models/sagas';
import ShortRaceSaga from 'x-common/bundles/ShortRaceBundle/models/sagas';
import FreeSpinsSaga from 'x-common/bundles/FreeSpinBundle/models/sagas';
import DocumentationAreaSaga from 'x-common/bundles/DocumentationAreaBundle/models/sagas';
import MetaPlayerReportSaga from 'x-common/bundles/MetaPlayerReportBundle/models/sagas';
import MetaSessionReportSaga from 'x-common/bundles/MetaSessionReportBundle/models/sagas';

import AppSaga from 'x-common/services/AppSaga';
import ApiClient from 'x-common/services/client/ApiClient';
import GeneralDataSaga from 'x-common/services/generalData/sagas';
import MediaResourcesSaga from 'x-common/services/media/sagas';
import ShortenerSagaService from 'x-common/services/shortener/models/sagas';

import migrations from 'migrations';

export default (apiClient: ApiClient) =>
  function* rootSaga(): Generator {
    const appSaga = new AppSaga({
      apiClient,
      AuthService: AuthSaga,
      GlossaryService: GeneralDataSaga,
      MigrationsService: migrations,
      services: [
        DashboardSaga,
        CompanySaga,
        BillingEntitySaga,
        UserSaga,
        UserGroupSaga,
        ReportSaga,
        SessionsReportSaga,
        PlayersReportSaga,
        PromotionsReportSaga,
        MetaSessionReportSaga,
        MetaPlayerReportSaga,
        MediaResourcesSaga,
        GamesAreaSaga,
        FreeSpinsSaga,
        DailyDropSaga,
        TournamentSaga,
        ShortRaceSaga,
        DocumentationAreaSaga,
        ShortenerSagaService,
      ],
    });
    yield appSaga.subscribe();
  };
